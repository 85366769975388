.navbar {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  background-color: #1F4C2E;
  border-bottom: 3px solid #ffdf00;
  pointer-events: fill;
  z-index: 1000;
}
.navbar a {
  cursor: pointer;
  font-weight: 600;
}
.navlink-wrapper {
  width: 20%;
}
.navlinks {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}
#hamburger-icon {
  width: 35px;
  height: 21px;
  position: relative;
  margin: 0px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
#hamburger-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: white;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
#hamburger-icon span:nth-child(1) {
  top: 0px;
}
#hamburger-icon span:nth-child(2),
#hamburger-icon span:nth-child(3) {
  top: 9px;
}
#hamburger-icon span:nth-child(4) {
  top: 18px;
}
#hamburger-icon.open span:nth-child(1) {
  top: 9px;
  width: 0%;
  left: 50%;
}
#hamburger-icon.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
#hamburger-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
#hamburger-icon.open span:nth-child(4) {
  top: 9px;
  width: 0%;
  left: 50%;
}
@media screen and (min-width: 600px) {
  .profile {
    width: 120px;
  }
  .hamburger {
    display: none;
  }
  .mobilenav {
    display: none;
  }
}
@media screen and (max-width: 599px) {
  .navlinks {
    display: none;
  }
  .hamburger {
    position: absolute;
    right: 30px;
    cursor: pointer;
  }
}
