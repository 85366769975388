footer {
  background-color: #1F4C2E;
  padding-bottom: 10px;
}
.copyright {
  text-align: center;
}
.copyright img {
  border-radius: 15px;
}
